import {setLogPrefix} from '@chancer/common/lib/utils/Log';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

setLogPrefix('[WEB]');

if (process.env.BUILD_ENVIRONMENT !== 'local') {
  Sentry.init({
    dsn: 'https://e7a5f71192b343c690ecdc0b7cdd1496@o449662.ingest.sentry.io/5432982',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: process.env.BUILD_ENVIRONMENT,
    release: process.env.RELEASE_HASH,
  });
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
