import {Epic, ofType} from 'redux-observable';
import {map} from 'rxjs/operators';
import {ActionType, IAction} from '../../actions/Actions';
import {setLocalStorage} from '../../actions/localStorage/LocalStorageActions';
import {IAppState} from '../../state/AppState';
import {LocalStorageKey} from '../../state/model/LocalStorage';

export const setLocalNewsReadDateEpic: Epic<
  IAction<{date: Date}>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SET_LOCAL_NEWS_READ),
    map((action) =>
      setLocalStorage(
        LocalStorageKey.NEWS_READ_DATE,
        action.payload.date.valueOf(),
      ),
    ),
  );
