import React, {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import * as clipboard from 'clipboard-polyfill';

import {getCompetition} from '@chancer/common/lib/core/selectors/competitions/CompetitionSelectors';
import {
  getShareLongUrl,
  getShareText,
  getShareTitle,
} from '@chancer/common/lib/core/selectors/share/ShareSelectors';
import {getUserId} from '@chancer/common/lib/core/selectors/user/UserSelectors';
import {TFirebaseComp} from '@chancer/common/lib/interfaces/firestore/FirestoreClientInterfaces';
import {BranchCustomKey} from '@chancer/common/lib/utils/Branch';
import {getColorConfiguration} from '@chancer/common/lib/utils/ColorUtils';
import log from '@chancer/common/lib/utils/Log';
import {SecondaryButton} from '@chancer/components/lib/Button/SecondaryButton';
import {ShareButton} from '@chancer/components/lib/Button/ShareButton';
import {CompositedViewStyle} from '@chancer/components/lib/Styles/StyleTypes';
import {trackException, trackShare} from '../../../analytics/FirebaseAnalytics';
import {getConfig} from '../../../selectors/config/WebConfigSelectors';
import {useCreateBranchLink} from '../../../utils/BranchUtils';
import './ShareComponent.scss';

enum CopyState {
  NOT_COPIED,
  ERROR,
  SUCCESS,
}

interface IProps {
  isOnBlack?: boolean;
  onClose?: () => void;
}

interface IStateProps {
  comp: TFirebaseComp | null;
  userId: string | null;
  shareUrl: string;
  shareTitle: string;
  shareText: string;
  facebookAppId: string;
}

interface IDispatchProps {}

export const ShareComponent: React.FC<IProps> = (props) => {
  return (
    <ShareComponentView
      {...{
        ...props,
        comp: useSelector(getCompetition),
        userId: useSelector(getUserId),
        shareUrl: useSelector(getShareLongUrl),
        shareTitle: useSelector(getShareTitle),
        shareText: useSelector(getShareText),
        facebookAppId: useSelector(getConfig).facebookConfig.appId,
      }}
    />
  );
};

export const ShareComponentView: React.FC<
  IProps & IStateProps & IDispatchProps
> = (props) => {
  const _isNativeShareSupported = () => (navigator as any).share !== undefined;
  const {userId, comp, shareUrl, shareText, shareTitle} = props;
  const [copied, setCopied] = useState(CopyState.NOT_COPIED);

  const linkDetails = useMemo(
    () => ({
      identifier: shareUrl!,
      title: shareTitle,
      webUrl: shareUrl,
      description: shareText,
      customMetadata: {
        ...(userId ? {[BranchCustomKey.USER_ID]: userId} : {}),
        ...(comp ? {[BranchCustomKey.COMP_ID]: comp.id} : {}),
      },
    }),
    [shareUrl, shareTitle, shareText, userId, comp],
  );
  const link = useCreateBranchLink(linkDetails);

  const _onNativeShare = async () => {
    log.info('onShare');
    if (link && _isNativeShareSupported()) {
      const shareData = {
        title: props.shareTitle,
        text: props.shareText,
        url: link,
      };
      try {
        await (navigator as any).share(shareData);
        trackShare('native_share');
      } catch (err) {
        try {
          trackException(`share ${err}`, false);
          // eslint-disable-next-line no-catch-shadow
        } catch (err) {
          log.error(err);
        }
      }
    }
  };

  const _onCopy = async () => {
    if (link) {
      try {
        await clipboard.writeText(link);
        setCopied(CopyState.SUCCESS);
        trackShare('copied');
      } catch (err) {
        setCopied(CopyState.ERROR);
      }
    }
  };

  const _openFacebookShareUrl = () => {
    if (link) {
      window.open(
        `https://www.facebook.com/dialog/share?app_id=${
          props.facebookAppId
        }&display=popup&href=${encodeURIComponent(link)}`,
        'chancerShareWindow',
        _getPopupParams(),
      );
      trackShare('direct_facebook');
    }
  };

  const _openWhatsAppShareUrl = () => {
    if (link) {
      const waShareUrl = isMobile()
        ? 'https://wa.me'
        : 'https://api.whatsapp.com/send';
      window.open(
        `${waShareUrl}/?text=${encodeURIComponent(
          `${props.shareText} - ${link}`,
        )}`,
        'chancerShareWindow',
        _getPopupParams(),
      );
      trackShare('direct_whatsapp');
    }
  };

  const isMobile = () => {
    const ua = navigator.userAgent ?? navigator.vendor;
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        ua,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        ua.substr(0, 4),
      )
    );
  };

  const _getPopupParams = () =>
    `height=700, width=550, top=${window.screenY}, left=${window.screenX}', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`;

  const colorConfig = getColorConfiguration(comp?.primaryColor);

  const isShareEnabled = link !== null;
  return (
    <div
      className="share__container"
      style={{backgroundColor: colorConfig.leaderboardItemsBackground}}>
      <div className="share__title">It's always more fun with friends…</div>
      <div className="share__copy">
        Share the game link using your personalised invite below.
      </div>

      <div className="share__buttons-container">
        <div
          className="share__button-icon share__button-icon-whatsapp"
          onClick={isShareEnabled ? _openWhatsAppShareUrl : undefined}>
          Whatsapp
        </div>
        <div
          className="share__button-icon share__button-icon-facebook"
          onClick={isShareEnabled ? _openFacebookShareUrl : undefined}>
          Facebook
        </div>
      </div>

      {_isNativeShareSupported() ? (
        <ShareButton
          style={styles.shareButton}
          withIcon={true}
          disabled={!isShareEnabled}
          onPress={_onNativeShare}
          label="Share using other app"
        />
      ) : (
        <ShareButton
          style={styles.shareButton}
          disabled={!isShareEnabled}
          onPress={_onCopy}
          withIcon={false}
          label={
            copied === CopyState.NOT_COPIED
              ? 'Copy invite link'
              : copied === CopyState.ERROR
                ? 'Copy failed'
                : 'Copied'
          }
        />
      )}
      {props.onClose !== undefined && (
        <SecondaryButton
          style={styles.closeButton}
          onPress={props.onClose}
          label="Maybe later"
        />
      )}
    </div>
  );
};

const styles: {[key: string]: CompositedViewStyle} = {
  shareButton: {
    marginTop: 32,
  },
  closeButton: {
    marginTop: 16,
  },
};
