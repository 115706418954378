import {Epic, ofType} from 'redux-observable';
import {concat, of} from 'rxjs';
import {delay, filter, map, switchMap} from 'rxjs/operators';

import {TFirebaseUser} from '../../../interfaces/firestore/FirestoreClientInterfaces';
import {getStartupOverlay} from '../../../interfaces/overlay/OverlayCreator';

import {AchievementType} from '../../../interfaces/achievements/Achievements';
import {
  IOverlay,
  OverlayType,
} from '../../../interfaces/overlay/OverlayInterfaces';
import {ActionType, IAction} from '../../actions/Actions';
import {fetchUpdateUser} from '../../actions/firestore/FirestoreActions';
import {setAnnouncementViewed} from '../../actions/localStorage/LocalStorageActions';
import {addOverlay} from '../../actions/overlay/OverlayActions';
import {getNextAnnouncementToShow} from '../../selectors/announcement/AnnouncementSelectors';
import {getFilteredCompSummaries} from '../../selectors/competitions/BaseCompSummarySelectors';
import {
  getCurrentOverlay,
  getPendingOverlays,
} from '../../selectors/overlay/OverlaySelectors';
import {getUser, getUserRequest} from '../../selectors/user/UserSelectors';
import {IAppState} from '../../state/AppState';

export const processNewAchievementsEpic: Epic<
  IAction<TFirebaseUser>,
  IAction<any>,
  IAppState
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.USER_RESPONSE),
    // Prevents this logic being triggered by the first USER_RESPONSE sent after login
    filter(
      (action) =>
        getUserRequest(state$.value) !== null &&
        (action.payload.completedComps !== undefined ||
          action.payload.passedThresholds !== undefined ||
          action.payload.completedChallenges !== undefined ||
          action.payload.completedAchievements !== undefined ||
          action.payload.completedMiniLeagues !== undefined),
    ),
    // completedAchievemnts and completedComps are processed by separate cloud functions
    // This delay allows all changes to be applied before showing the overlay and wiping the existing values
    delay(5000),
    map(() => getUser(state$.value)),
    filter((user) => user !== null),
    map((user) => ({
      user,
      achievements: (user.completedAchievements ?? [])
        .filter((v) =>
          v.achievements.some(
            (a, idx) =>
              a > 0 &&
              showOverlayForAchievement(idx) &&
              getFilteredCompSummaries(state$.value).find(
                (s) => s.id === v.compId,
              ) !== undefined,
          ),
        )
        .map((v) => ({
          ...v,
          achievements: v.achievements.map((a, idx) =>
            showOverlayForAchievement(idx) ? a : 0,
          ),
        })),
      passedThresholds: (user.passedThresholds ?? []).filter(
        (c) =>
          getFilteredCompSummaries(state$.value).find(
            (s) => s.id === c.compId,
          ) !== undefined,
      ),
      summaries: (user.completedComps ?? []).filter(
        (c) =>
          getFilteredCompSummaries(state$.value).find(
            (s) => s.id === c.compId,
          ) !== undefined,
      ),
      challenges: (user.completedChallenges ?? []).filter(
        (c) =>
          getFilteredCompSummaries(state$.value).find(
            (s) => s.id === c.compId,
          ) !== undefined,
      ),
      miniLeagues: user.completedMiniLeagues ?? [], // TODO
      announcement: getNextAnnouncementToShow(state$.value),
    })),
    filter(
      (startupScreens) =>
        startupScreens.achievements.length > 0 ||
        startupScreens.passedThresholds.length > 0 ||
        startupScreens.summaries.length > 0 ||
        startupScreens.challenges.length > 0 ||
        startupScreens.miniLeagues.length > 0,
    ),
    switchMap((startupScreens) =>
      concat(
        of(
          getStartupOverlay(
            startupScreens.user,
            startupScreens.achievements,
            startupScreens.passedThresholds,
            startupScreens.summaries,
            startupScreens.challenges,
            startupScreens.miniLeagues,
            startupScreens.announcement,
          ),
        ).pipe(
          filter(
            (overlay) =>
              !isSameOverlay(getCurrentOverlay(state$.value), overlay),
          ),
          filter(
            (overlay) =>
              getPendingOverlays(state$.value).find((o) =>
                isSameOverlay(o, overlay),
              ) === undefined,
          ),
          switchMap((overlay) =>
            concat(
              of(addOverlay(overlay)),
              of(startupScreens.announcement).pipe(
                filter((announcement) => announcement !== null),
                map((announcement) => setAnnouncementViewed(announcement.id)),
              ),
            ),
          ),
        ),
        of(
          fetchUpdateUser({
            achievementsNew: undefined,
            vendorAchievementsNew: undefined,
            passedThresholds: undefined,
            completedComps: undefined,
            completedChallenges: undefined,
            completedMiniLeagues: undefined,
            completedAchievements: undefined,
          }),
        ),
      ),
    ),
  );

// TODO: do we need to do more and actually check the parameters?
// for now I am assuming we just don't want to ever do another startup
// overlay if one is already being shown
const isSameOverlay = (
  overlay1: IOverlay | null,
  overlay2: IOverlay,
): boolean =>
  overlay1 !== null &&
  overlay1.type === OverlayType.STARTUP &&
  overlay2.type === OverlayType.STARTUP;

const showOverlayForAchievement = (achievement: AchievementType): boolean =>
  achievement === AchievementType.GAME_WINNER ||
  achievement === AchievementType.WINNER;
